<template>
  <div class="object-statistic-price">
    <objects-realty-statistic-summary-section :values="summaryValues" arrowed class="object-statistic-price__summary" />

    <v-chart-with-filters
      class="object-statistic-price__change"
      type="line"
      :title="changeTitle"
      :datasets="change.datasets"
      :labels="change.labels"
      :titles="change.titles"
      :filters="change.filters"
      money-round
      with-date-filter
      with-display-market
      :chart-options="{ xAxesType: 'time', yAxesRoundMoney: true, yAxesBeginAtZero: false }"
      @change-filters="changeFilters"
    />

    <template v-if="!isArchive">
      <v-chart-with-filters
        class="object-statistic-price__similar-graph"
        type="bar"
        :title="similarGraphTitle"
        :datasets="similar.datasets"
        :labels="similar.labels"
        units="млн"
        :chart-options="{ yAxesRound: true, xAxesType: 'category', stacked: true, isNotLabelDate: true }"
      />

      <div class="object-statistic-price__similar">
        <v-page-subtitle class="object-statistic-price__similar-title"
          >Объекты, которые участвуют в сравнительном анализе</v-page-subtitle
        >
        <div class="object-statistic-price__similar-list">
          <objects-realty-list-item
            v-for="object in similar.list"
            :key="object.id"
            v-bind="object"
            is-extended-view
            hide-actions
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ObjectsRealtyStatisticSummarySection from '@/components/common/SummarySection.vue'
import ObjectsRealtyListItem from '@/components/ObjectsRealty/ListItem.vue'
import VChartWithFilters from '@/components/common/VChartWithFilters.vue'
import VPageSubtitle from '@/components/common/VPageSubtitle.vue'
import { advertsService } from '@/services/http'
import { loadingService } from '@/services/loading'
import { formatMoney, formatObjectRealty, roundMoney, roundMoneyWithoutPrefix } from '@/utils/formatters'
import { CUSTOM_DATE_TYPE, SIX_MONTH_TYPE, THIS_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { abbreviatedDate, createChartDataset } from '@/utils/common'

export default {
  name: 'ObjectsRealtyStatisticPrice',
  components: {
    ObjectsRealtyStatisticSummarySection,
    VChartWithFilters,
    ObjectsRealtyListItem,
    VPageSubtitle
  },
  props: {
    objectId: { type: [Number, String], required: true },
    isArchive: { type: Boolean, default: false }
  },
  inject: ['mediaQueries', 'graphsMainColor'],
  data() {
    return {
      summary: null,

      change: {
        filters: {
          dateRangeType: SIX_MONTH_TYPE,
          dateFrom: null,
          dateTo: null,
          isDisplayMarket: false
        },
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      similar: {
        datasets: [],
        labels: [],
        list: []
      }
    }
  },
  computed: {
    changeTitle() {
      if (this.mediaQueries.mobile) return 'Изменение цены'
      if (this.mediaQueries.tablet) return 'График изменения цены'
      return 'График изменения цены объекта'
    },
    similarGraphTitle() {
      if (this.mediaQueries.mobile) return 'Объекты в схожей ценовой категории'
      return 'Анализ цен среди схожих объектов'
    },
    summaryValues() {
      if (!this.summary) return []

      const endText = this.isArchive ? 'финальная' : 'сейчас'
      const prependIcon = this.summary.currency === 'EUR' ? '€' : '$'
      const currencyStartPrice =
        this.summary.currency === 'EUR' ? this.summary.startPriceEur : this.summary.startPriceUsd
      const currencyCurrentPrice = this.summary.currency === 'EUR' ? this.summary.priceEur : this.summary.priceUsd
      const currencyPriceChange =
        this.summary.currency === 'EUR' ? this.summary.eurPriceChange : this.summary.usdPriceChange

      return [
        { title: `Цена начальная`, value: this.formatPrice(this.summary.startPrice), prepend: '₽' },
        {
          title: `Цена ${endText}`,
          value: this.formatPrice(this.summary.price),
          change: this.summary.priceChange,
          prepend: '₽'
        },
        { title: 'Цена начальная', value: this.formatPrice(currencyStartPrice), prepend: prependIcon },
        {
          title: `Цена ${endText}`,
          value: this.formatPrice(currencyCurrentPrice),
          change: currencyPriceChange,
          prepend: prependIcon
        }
      ]
    }
  },
  created() {
    loadingService.setViewLoading(true)

    const summaryRequest = advertsService.getAdvertPriceSummary(this.objectId).then(data => {
      this.summary = data
    })
    const changeRequest = this.fetchChangeGraph()
    const similarRequest = this.fetchSimilarObjects()

    Promise.all([summaryRequest, changeRequest, similarRequest]).finally(() => {
      loadingService.setViewLoading(false)
    })
  },
  methods: {
    fetchChangeGraph() {
      return advertsService.getAdvertPriceChange(this.objectId, this.change.filters).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Цена объекта',
          filled: true
        })

        datasets.push(defaultDataset)

        if (data.similarGraph) {
          const similarDataset = createChartDataset({
            data: data.similarGraph,
            color: 'green',
            label: 'Цена рынка'
          })
          datasets.push(similarDataset)
          this.change.titles.similar = data.similarGraph.map(i => i.date)
        } else {
          this.change.titles.similar = []
        }

        this.change.datasets = datasets
        this.change.labels = data.graph.map(i => i.date)
        this.change.titles.current = data.graph.map(i => i.date)

        if (
          this.change.filters.dateRangeType !== THIS_MONTH_TYPE &&
          this.change.filters.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.change.labels = abbreviatedDate(this.change.labels)
          this.change.titles.current = abbreviatedDate(this.change.titles.current)
          this.change.titles.similar = abbreviatedDate(this.change.titles.similar)
        }
      })
    },
    fetchSimilarObjects() {
      return advertsService.getAdvertPriceSimilar(this.objectId).then(data => {
        const sortedDatasets = [...data.similarAdverts, data.current].sort((a, b) => b.price - a.price)
        const length = sortedDatasets.length + 1

        this.similar.datasets = sortedDatasets.map((el, index) => {
          const price = roundMoneyWithoutPrefix(el.price)
          const datalist = Array.from({ length }, (_, i) => (i === index ? price : null))
          const isCurrent = Number(el.id) === Number(this.objectId)
          const color = isCurrent ? this.graphsMainColor : 'gray'
          const label = isCurrent ? 'Этот объект' : el.name

          return createChartDataset({ data: datalist, color, label, barThickness: 24 })
        })

        this.similar.labels = sortedDatasets.map(el => `№ ${el.id}`)
        this.similar.list = data.similarAdverts.map(el => formatObjectRealty(el))
      })
    },
    formatPrice(value) {
      if (value >= 1000000) return roundMoney(value)
      return formatMoney(value)
    },
    changeFilters(filters) {
      this.change.filters = filters
      this.fetchChangeGraph()
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';

.object-statistic-price {
  &__summary {
    margin-bottom: 50px;

    @include tablet {
      margin-bottom: 32px;
    }

    @include phone {
      padding: 0 24px;
    }
  }

  &__change {
    margin-bottom: 48px;
  }

  &__similar-title {
    margin-bottom: 16px;

    @include tablet {
      white-space: normal;
    }

    @include phone {
      padding: 0 24px;
      margin-bottom: 24px;
    }
  }

  &__similar-graph {
    margin-bottom: 48px;

    @include tablet {
      margin-bottom: 32px;
    }
  }
}
</style>
