<template>
  <match-media v-slot="{ mobile }">
    <div class="object-statistic">
      <div class="object-statistic__header">
        <div class="object-statistic__title">
          <v-breadcrumbs v-if="!mobile" :items="breadcrumbItems" class="object-statistic__breadcrumbs" />

          <v-button-go-back v-else @click="goBack" />

          <v-page-title class="object-statistic__title">{{ objectRealtyItem.name }}</v-page-title>
        </div>
        <div v-if="object" class="object-statistic__period-section">
          <span v-if="!isArchive" class="object-statistic__period-title">{{ onSaleText }} c {{ fromDate }}</span>
          <span v-else class="object-statistic__period-title">{{ onSaleText }} c {{ fromDate }} по {{ toDate }}</span>
          <span class="object-statistic__period-days">{{ daysOnSaleText }}</span>
        </div>
      </div>

      <div v-if="object" class="object-statistic__object">
        <div class="object-statistic__object-wrapper">
          <v-list-item
            class="object-statistic__object-card"
            is-extended-view
            v-bind="objectRealtyItem"
            disable-links
            hide-actions
            no-desktop-view
          />
        </div>
      </div>
      <div class="object-statistic__tabs-wrapper">
        <v-tabs v-model="activeTab" class="object-statistic__tabs" :primary="mobile">
          <v-tab v-for="(tab, index) of $options.TABS" :key="index" :name="tab.key">{{ tab.label }}</v-tab>
        </v-tabs>
      </div>

      <div class="object-statistic__section-wrapper">
        <objects-realty-statistic-price
          v-if="activeTab === $options.PRICE_TAB"
          :key="objectId"
          :object-id="objectId"
          :is-archive="isArchive"
        />
        <objects-realty-statistic-calls
          v-if="activeTab === $options.CALLS_TAB"
          :key="objectId"
          :object-id="objectId"
          :is-archive="isArchive"
          :hide-list="!isCurrentUserAttachedAgent"
        />
        <objects-realty-statistic-shows
          v-if="activeTab === $options.SHOWS_TAB"
          :key="objectId"
          :object-id="objectId"
          :is-archive="isArchive"
          :hide-list="!isCurrentUserAttachedAgent"
        />
      </div>
    </div>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import VListItem from '@/components/ObjectsRealty/ListItem.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import ObjectsRealtyStatisticPrice from '@/components/ObjectsRealty/Statistic/Price.vue'
import ObjectsRealtyStatisticCalls from '@/components/ObjectsRealty/Statistic/Calls.vue'
import ObjectsRealtyStatisticShows from '@/components/ObjectsRealty/Statistic/Shows.vue'
import { advertsService } from '@/services/http'
import { formatObjectRealty } from '@/utils/formatters'
import { REALTY_STATUS_ACTIVE, REALTY_STATUS_ARCHIVE } from '@/constants/statuses/realty'
import { loadingService } from '@/services/loading'
import { pluralizeNumeral } from '@/utils/pluralization'
import { mapGetters, mapState } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

const PRICE_TAB = 'price'
const CALLS_TAB = 'calls'
const SHOWS_TAB = 'shows'
const TABS = [
  { key: PRICE_TAB, label: 'Стоимость' },
  { key: CALLS_TAB, label: 'Лиды' },
  { key: SHOWS_TAB, label: 'Показы' }
]
export default {
  TABS,
  PRICE_TAB,
  CALLS_TAB,
  SHOWS_TAB,
  name: 'ObjectsRealtyStatistic',
  components: {
    MatchMedia,
    VBreadcrumbs,
    VButtonGoBack,
    VPageTitle,
    VListItem,
    VTabs,
    VTab,
    ObjectsRealtyStatisticPrice,
    ObjectsRealtyStatisticCalls,
    ObjectsRealtyStatisticShows
  },
  data: () => {
    return {
      object: null,
      status: null,
      attachedAgentId: null
    }
  },
  provide() {
    return {
      graphsMainColor: this.graphsMainColor
    }
  },
  computed: {
    ...mapState(MODULE_SESSION, {
      currentUserId: state => state.model.id
    }),
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    graphsMainColor() {
      return this.isNmh ? 'yellow' : 'blue'
    },
    isCurrentUserAttachedAgent() {
      return this.isRoleAdmin || this.currentUserId === this.attachedAgentId
    },
    activeTab: {
      get() {
        return this.$route.query.tab || null
      },
      set(val) {
        if (this.$route.query.tab) this.$router.push({ query: { tab: val } })
        else this.$router.replace({ query: { tab: val } })
      }
    },
    objectRealtyItem() {
      return this.object ? { ...formatObjectRealty(this.object), status: this.status } : null
    },
    onSaleText() {
      return `В продаже${this.isArchive ? ' был' : ''}`
    },
    daysOnSaleText() {
      const days = this.objectRealtyItem.onSale
      const text = pluralizeNumeral(days, 'день', 'дня', 'дней')
      return `${days} ${text}`
    },
    fromDate() {
      return this.object.publishedDate
    },
    toDate() {
      return this.object.saleEndDate || this.object.archivedDate
    },
    isArchive() {
      return this.status === REALTY_STATUS_ARCHIVE
    },
    breadcrumbItems() {
      return [
        {
          text: 'Объекты недвижимости',
          to: { name: 'objects-realty' }
        },
        {
          text: 'Аналитика объекта',
          disabled: true
        }
      ]
    },
    objectId() {
      return this.$route.params.objectId
    }
  },
  watch: {
    objectId(val) {
      if (val) {
        this.init()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'objects-realty' })
    },
    init() {
      if (!this.activeTab) this.activeTab = PRICE_TAB
      this.fetchData()
    },
    fetchData() {
      this.object = null
      this.status = null
      loadingService.setViewLoading(true)
      advertsService
        .getAdvert(this.objectId)
        .then(({ isPublished, agent: { id: agentId } }) => {
          this.attachedAgentId = agentId
          this.status = isPublished ? REALTY_STATUS_ACTIVE : REALTY_STATUS_ARCHIVE
        })
        .then(() => {
          advertsService.getMainAnalyticsInfo(this.objectId).then(res => {
            this.object = res
            loadingService.setViewLoading(false)
          })
        })
    }
  }
}
</script>
