<template>
  <div class="object-statistic-calls">
    <objects-realty-statistic-summary-section :values="summaryValues" class="object-statistic-calls__summary" />

    <v-chart-with-filters
      class="object-statistic-calls__change"
      type="line"
      :title="changeTitle"
      :datasets="change.datasets"
      :labels="change.labels"
      :titles="change.titles"
      :filters="change.filters"
      with-date-filter
      with-display-market
      :chart-options="{ xAxesType: 'time', yAxesRound: false, yAxesBeginAtZero: false }"
      @change-filters="changeFilters"
    />

    <v-chart-with-filters
      v-if="!isArchive"
      class="object-statistic-calls__similar-graph"
      type="bar"
      :title="similarGraphTitle"
      :datasets="similar.datasets"
      :labels="similar.labels"
      :chart-options="{ yAxesRound: false, xAxesType: 'category', stacked: true, isNotLabelDate: true }"
    />

    <calls-list
      v-if="!hideList"
      class="object-statistic-calls__list"
      :list="calls.list"
      :count="calls.params.count"
      @reload="refetchCallsList"
    />

    <div v-if="!isArchive" class="object-statistic-calls__similar">
      <v-page-subtitle class="object-statistic-calls__similar-title"
        >Объекты, которые участвуют в сравнительном анализе</v-page-subtitle
      >
      <div class="object-statistic-calls__similar-list">
        <objects-realty-list-item
          v-for="object in similar.list"
          :key="object.id"
          v-bind="object"
          is-extended-view
          hide-actions
        />
      </div>
    </div>
  </div>
</template>

<script>
import ObjectsRealtyStatisticSummarySection from '@/components/common/SummarySection.vue'
import ObjectsRealtyListItem from '@/components/ObjectsRealty/ListItem.vue'
import VChartWithFilters from '@/components/common/VChartWithFilters.vue'
import VPageSubtitle from '@/components/common/VPageSubtitle.vue'
import CallsList from '@/components/Agent/Calls/List.vue'
import { advertsService } from '@/services/http'
import { loadingService } from '@/services/loading'
import { formatObjectRealty } from '@/utils/formatters'
import { CUSTOM_DATE_TYPE, SIX_MONTH_TYPE, THIS_MONTH_TYPE } from '@/constants/dateRangeTypes'
import { abbreviatedDate, createChartDataset } from '@/utils/common'

export default {
  name: 'ObjectsRealtyStatisticCalls',
  components: {
    ObjectsRealtyStatisticSummarySection,
    ObjectsRealtyListItem,
    VChartWithFilters,
    VPageSubtitle,
    CallsList
  },
  props: {
    objectId: { type: [Number, String], required: true },
    isArchive: { type: Boolean, default: false },
    hideList: { type: Boolean, default: false }
  },
  inject: ['mediaQueries', 'graphsMainColor'],
  data() {
    return {
      summary: null,
      ownerCallSources: [],
      customerCallSources: [],
      change: {
        filters: {
          dateRangeType: SIX_MONTH_TYPE,
          dateFrom: null,
          dateTo: null,
          isDisplayMarket: false
        },
        datasets: [],
        labels: [],
        titles: {
          current: [],
          similar: []
        }
      },
      similar: {
        datasets: [],
        labels: [],
        list: []
      },
      calls: {
        list: [],
        params: {
          limit: 10,
          offset: 0,
          count: 0
        }
      }
    }
  },
  computed: {
    changeTitle() {
      if (this.isArchive) return 'График истории лидов'
      return 'История лидов'
    },
    similarGraphTitle() {
      if (this.mediaQueries.mobile) return 'Объекты в схожей ценовой категории'
      if (this.mediaQueries.tablet) return 'Анализ показов среди схожих объектов'
      return 'Анализ лидов среди схожих объектов (последние 30 дней)'
    },
    summaryValues() {
      if (!this.summary) return []

      const values = [
        { title: 'Кол-во лидов', value: this.summary.totalCalls },
        { title: 'Средн. в месяц', value: this.summary.averageCalls }
      ]

      if (!this.isArchive) {
        values.push({ title: 'Кол-во лидов (рынок)', value: this.summary.similarAdvertsTotalCalls })
        values.push({ title: 'Средн. в месяц (рынок)', value: this.summary.similarAdvertsAverageCalls })
      }

      return values
    }
  },
  created() {
    loadingService.setViewLoading(true)

    const summaryRequest = advertsService.getAdvertCallsSummary(this.objectId).then(data => {
      this.summary = data
    })
    const changeRequest = this.fetchChangeGraph()
    const barGraphRequest = this.fetchBarGraph()
    const listRequest = this.fetchCallsList()
    const listSimilarObjects = this.fetchSimilarObjects()

    Promise.all([summaryRequest, changeRequest, barGraphRequest, listRequest, listSimilarObjects]).finally(() => {
      loadingService.setViewLoading(false)
    })
  },
  methods: {
    fetchChangeGraph() {
      return advertsService.getAdvertCallsChange(this.objectId, this.change.filters).then(data => {
        const datasets = []

        const defaultDataset = createChartDataset({
          data: data.graph,
          color: this.graphsMainColor,
          label: 'Лиды объекта',
          filled: true
        })
        datasets.push(defaultDataset)

        if (data.similarGraph) {
          const similarDataset = createChartDataset({
            data: data.similarGraph,
            color: 'green',
            label: 'Лиды в среднем по рынку'
          })
          datasets.push(similarDataset)
          this.change.titles.similar = data.similarGraph.map(i => i.date)
        } else {
          this.change.titles.similar = []
        }

        this.change.datasets = datasets
        this.change.labels = data.graph.map(i => i.date)
        this.change.titles.current = data.graph.map(i => i.date)

        if (
          this.change.filters.dateRangeType !== THIS_MONTH_TYPE &&
          this.change.filters.dateRangeType !== CUSTOM_DATE_TYPE
        ) {
          this.change.labels = abbreviatedDate(this.change.labels)
          this.change.titles.current = abbreviatedDate(this.change.titles.current)
          this.change.titles.similar = abbreviatedDate(this.change.titles.similar)
        }
      })
    },
    fetchBarGraph() {
      return advertsService.getAdvertCallsBarGraph(this.objectId).then(data => {
        const sortedDatasets = [...data.similarAdverts, data.advert].sort(
          (a, b) => b.monthCallsCount - a.monthCallsCount
        )
        const length = sortedDatasets.length + 1

        this.similar.datasets = sortedDatasets.map((el, index) => {
          const datalist = Array.from({ length }, (_, i) => (i === index ? el.monthCallsCount : null))
          const isCurrent = Number(el.id) === Number(this.objectId)
          const color = isCurrent ? this.graphsMainColor : 'gray'
          const label = isCurrent ? 'Этот объект' : el.name

          return createChartDataset({ data: datalist, color, label, barThickness: 24 })
        })
        this.similar.labels = sortedDatasets.map(el => `№ ${el.id}`)
      })
    },
    fetchCallsList(params) {
      const fetchParams = { ...this.calls.params, ...params, orderBy: '-call_date' }
      return advertsService.getAdvertCallsList(this.objectId, fetchParams).then(({ count, results }) => {
        this.calls.list = results
        this.calls.params = { ...params, count }
      })
    },
    // ! Временная копипаста
    fetchSimilarObjects() {
      return advertsService.getAdvertPriceSimilar(this.objectId).then(data => {
        this.similar.list = data.similarAdverts.map(el => formatObjectRealty(el))
      })
    },
    refetchCallsList(params) {
      loadingService.setViewLoading(true)
      this.fetchCallsList(params).finally(() => {
        loadingService.setViewLoading(false)
      })
    },
    changeFilters(filters) {
      this.change.filters = filters
      this.fetchChangeGraph()
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';

.object-statistic-calls {
  &__summary {
    margin-bottom: 48px;

    @include tablet {
      margin-bottom: 32px;
    }

    @include phone {
      padding: 0 24px;
    }
  }

  &__change {
    margin-bottom: 48px;

    @include phone {
      margin-bottom: 32px;
    }
  }

  &__similar-title {
    margin-bottom: 16px;

    @include tablet {
      white-space: normal;
    }

    @include phone {
      padding: 0 24px;
      margin-bottom: 24px;
    }
  }

  &__similar-graph {
    margin-bottom: 48px;

    @include tablet {
      margin-bottom: 32px;
    }
  }

  &__list {
    margin-bottom: 48px;

    @include tablet {
      margin-bottom: 32px;
    }
  }
}
</style>
