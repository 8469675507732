<template>
  <match-media v-slot="{ mobile }">
    <v-table-row class="statistic-show-item">
      <div class="statistic-show-item__cell statistic-show-item__cell--date">
        {{ showDate }}
      </div>

      <div class=" statistic-show-item__cell  statistic-show-item__cell--agent">
        <v-avatar size="small" :src="agent.photo" class=" statistic-show-item__avatar" />
        <span v-if="!mobile" class=" statistic-show-item__agent-name">{{ agent.name }}</span>
      </div>

      <div class=" statistic-show-item__cell  statistic-show-item__cell--info">
        <span class=" statistic-show-item__title">{{ advert.name }}</span>
        <span class=" statistic-show-item__name">{{ clientName }}</span>
      </div>

      <div class=" statistic-show-item__cell  statistic-show-item__cell--phone">{{ phoneMask }}</div>

      <div v-if="isRoleAdmin" class=" statistic-show-item__actions">
        <v-popover-actions class=" statistic-show-item__popover">
          <v-button-icon v-if="!mobile">
            <v-icon-dots />
          </v-button-icon>
          <span v-else class=" statistic-show-item__mobile-actions"> </span>
          <template #actions="{ onClick }">
            <v-button-wide @click="onClick(editShow)">Редактировать</v-button-wide>
            <v-button-wide @click="onClick(deleteShow)">Удалить</v-button-wide>
          </template>
        </v-popover-actions>
      </div>
    </v-table-row>
  </match-media>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VTableRow from '@/components/common/VTableRow.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { formatPhone } from '@/utils/formatters'

export default {
  name: 'ShowsStatisticListItem',
  components: {
    MatchMedia,
    VAvatar,
    VIconDots,
    VButtonWide,
    VTableRow,
    VPopoverActions,
    VButtonIcon
  },
  props: {
    advert: { type: Object, required: true },
    agent: { type: Object, required: true },
    showDate: { type: String, required: true },
    clientName: { type: String, required: true },
    clientPhone: { type: String, required: true }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`,
      isRoleOwner: `${MODULE_SESSION}/isRoleOwner`
    }),
    phoneMask() {
      if (this.isRoleOwner) {
        return this.clientPhone
      }
      return this.clientPhone ? formatPhone(this.clientPhone) : ''
    }
  },
  methods: {
    editShow() {
      this.$emit('edit')
    },
    deleteShow() {
      this.$emit('delete')
    }
  }
}
</script>
