<template>
  <match-media v-slot="{ mobile }">
    <v-table-row class="call-item">
      <div class="call-item__cell call-item__cell--date">
        {{ callDate }}
      </div>

      <div class="call-item__cell call-item__cell--agent">
        <v-avatar size="small" :src="agent.photo" class="call-item__avatar" />
        <span v-if="!mobile" class="call-item__agent-name">{{ agent.name }}</span>
      </div>

      <div class="call-item__cell call-item__cell--info">
        <span class="call-item__title">{{ advert.name }}</span>
        <span class="call-item__name">{{ localClientName }}</span>
      </div>

      <div class="call-item__cell call-item__cell--phone">{{ localClientPhone }}</div>

      <div v-if="isRoleAdmin" class="call-item__actions">
        <v-popover-actions class="call-item__popover">
          <v-button-icon v-if="!mobile">
            <v-icon-dots />
          </v-button-icon>
          <span v-else class="call-item__mobile-actions"> </span>
          <template #actions="{ onClick }">
            <v-button-wide @click="onClick(editCall)">Редактировать</v-button-wide>
            <v-button-wide @click="onClick(deleteCall)">Удалить</v-button-wide>
          </template>
        </v-popover-actions>
      </div>
    </v-table-row>
  </match-media>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VTableRow from '@/components/common/VTableRow.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { formatPhone } from '@/utils/formatters'

export default {
  name: 'AgentCallsListItem',
  components: {
    MatchMedia,
    VAvatar,
    VIconDots,
    VButtonWide,
    VTableRow,
    VPopoverActions,
    VButtonIcon
  },
  props: {
    account: { type: Object, required: true },
    advert: { type: Object, required: true },
    agent: { type: Object, required: true },
    callDate: { type: String, required: true },
    clientName: { type: String, default: '' },
    clientPhone: { type: String, default: '' }
  },
  computed: {
    localClientName() {
      return this.account?.name || this.clientName
    },
    localClientPhone() {
      const phone = this.account?.phone || this.clientPhone
      return formatPhone(phone)
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    })
  },
  methods: {
    editCall() {
      this.$emit('edit')
    },
    deleteCall() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';
@import 'src/assets/scss/mixins';

.call-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 17px;
  font-size: $--font-size-medium;

  @include phone {
    padding: 17px 17px 17px 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
  }

  &__cell {
    @include tablet-and-higher {
      @include linear-text;
    }

    &--date {
      flex: 0 0 20%;
      margin-right: 10px;

      @include phone {
        position: absolute;
        right: 17px;
        top: 17px;
        margin-right: 0;
      }
    }

    &--agent {
      @include tablet-and-higher {
        flex-basis: 340px;
        margin-right: 12px;
      }

      display: flex;
      align-items: center;

      @include phone {
        position: absolute;
        right: 17px;
        bottom: 17px;
      }
    }

    &--info {
      display: flex;
      flex-direction: column;

      @include tablet-and-higher {
        flex-basis: 280px;
        margin-right: 12px;
      }

      @include phone {
        margin-right: 100px;
      }
    }

    &--phone {
      @include tablet-and-higher {
        flex: 0 0 135px;
      }
    }
  }

  &__avatar {
    @include tablet-and-higher {
      margin-right: 8px;
    }
  }

  &__agent-name {
    @include linear-text;

    font-weight: $--font-weight-medium;
  }

  &__title {
    @include tablet-and-higher {
      @include linear-text;
    }

    font-size: 14px;
    margin-bottom: 4px;
    font-weight: $--font-weight-medium;

    @include phone {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  &__name {
    @include linear-text;

    @include tablet-and-higher {
      font-size: 13px;
      color: $--silver;
    }

    @include phone {
      margin-bottom: 8px;
    }
  }

  &__mobile-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
