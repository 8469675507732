<template>
  <div class="calls-list">
    <div class="calls-list__header">
      <v-page-subtitle class="calls-list__title">
        Список лидов
        <template #title-postfix>{{ count }}</template>
      </v-page-subtitle>

      <v-button v-if="isCanDisplayButton" primary class="calls-list__button" @click="addCall">
        <v-icon-plus class="calls-list__button-icon" />
        Добавить лид
      </v-button>

      <div class="calls-list__searchrow">
        <v-searchrow :value="query" @search="search" />
      </div>
    </div>

    <div class="calls-list__list">
      <calls-list-item
        v-for="(call, index) in list"
        :key="index"
        v-bind="call"
        @edit="editCall(call)"
        @delete="deleteCall(call)"
      />
    </div>

    <div class="calls-list__pagination">
      <v-pagination :current="page" :total="totalPages" @change="changePage" />
    </div>
    <calls-create-dialog
      v-if="isRoleAdmin"
      :visible.sync="editDialogVisible"
      :call-data="model"
      @submitted="reloadList"
    />
  </div>
</template>

<script>
import VPageSubtitle from '@/components/common/VPageSubtitle.vue'
import CallsListItem from '@/components/Agent/Calls/ListItem.vue'
import VPagination from '@/components/common/VPagination.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import VButton from '@/components/common/VButton.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { agentsService } from '@/services/http'
import { loadingService } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import CallsCreateDialog from '@/components/Calls/CreateDialog.vue'

export default {
  name: 'AgentCallsList',
  components: { CallsCreateDialog, VPageSubtitle, CallsListItem, VPagination, VSearchrow, VButton, VIconPlus },
  props: {
    isCanDisplayButton: { type: Boolean, default: false },
    list: { type: Array, default: () => [] },
    count: { type: Number, default: 0 },
    limit: { type: Number, default: 10 }
  },
  data() {
    return {
      query: '',
      page: 1,

      loading: false,
      editDialogVisible: false,
      model: null
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    params() {
      const offset = (this.page - 1) * this.limit
      return {
        search: this.query,
        limit: this.limit,
        offset
      }
    },
    totalPages() {
      return Math.ceil(this.count / this.limit)
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  methods: {
    reloadList() {
      this.$emit('reload', { ...this.params })
    },
    search(query) {
      this.query = query
      this.reloadList()
    },
    changePage(page) {
      this.page = page
      this.reloadList()
    },

    addCall() {
      this.model = {}
      this.editDialogVisible = true
    },
    editCall({ id }) {
      agentsService
        .getCallById(id)
        .then(result => {
          this.model = { ...result, callSource: result.callSourceName }
          this.editDialogVisible = true
        })
        .catch(() => noticeService.error('Не удалось загрузить данные лида'))
    },
    deleteCall(call) {
      confirmService
        .ask({ title: 'Вы точно хотите удалить лид?' })
        .then(() => {
          this.loading = true
          agentsService
            .deleteCall(call.id)
            .then(() => {
              this.reloadList()
              noticeService.success('Лид удален')
            })
            .catch(() => noticeService.error('Не удалось удалить лид'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/media';
@import 'src/assets/scss/mixins';

.calls-list {
  &__header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include phone {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px;
      margin-bottom: 24px;
    }
  }

  &__title {
    @include linear-text;

    @include phone {
      margin-bottom: 24px;
    }
  }

  &__button {
    width: 100%;
    margin-bottom: 24px;
  }

  &__count {
    color: $--pitch-black-40percent;
  }

  &__searchrow {
    width: 230px;

    @include phone {
      width: 100%;
    }
  }

  &__list {
    margin-bottom: 24px;
  }

  &__pagination {
    @include phone {
      padding: 0 17px;
    }
  }
}
</style>
