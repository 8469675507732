<template>
  <div class="shows-list">
    <div class="shows-list__header">
      <v-page-subtitle class="shows-list__title">
        Список показов
        <template #title-postfix> {{ count }} </template>
      </v-page-subtitle>

      <v-button v-if="isCanDisplayButton" primary class="shows-list__button" @click="addShow">
        <v-icon-plus class="shows-list__button-icon" />
        Добавить показ
      </v-button>

      <div class="shows-list__searchrow">
        <v-searchrow :value="query" @search="search" />
      </div>
    </div>

    <div class="shows-list__list">
      <shows-statistic-list-item
        v-for="(show, index) in list"
        :key="index"
        v-bind="show"
        @edit="editShow(show)"
        @delete="deleteShow(show)"
      ></shows-statistic-list-item>
    </div>

    <div class="shows-list__pagination">
      <v-pagination :current="page" :total="totalPages" @change="changePage" />
    </div>

    <shows-create-dialog
      :visible.sync="editDialogVisible"
      :show-data="model"
      @submitted="reloadList"
    ></shows-create-dialog>
  </div>
</template>

<script>
import VPageSubtitle from '@/components/common/VPageSubtitle.vue'
import ShowsCreateDialog from '@/components/Shows/CreateDialog.vue'
import VPagination from '@/components/common/VPagination.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import VButton from '@/components/common/VButton.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { agentsService } from '@/services/http'
import { loadingService } from '@/services/loading'
import ShowsStatisticListItem from '@/components/Shows/StatisticListItem.vue'

export default {
  name: 'ShowsList',
  components: {
    ShowsStatisticListItem,
    ShowsCreateDialog,
    VPageSubtitle,
    VPagination,
    VSearchrow,
    VButton,
    VIconPlus
  },
  props: {
    isCanDisplayButton: { type: Boolean, default: false },
    list: { type: Array, default: () => [] },
    count: { type: Number, default: 0 },
    limit: { type: Number, default: 10 }
  },
  data() {
    return {
      query: '',
      page: 1,

      loading: false,
      editDialogVisible: false,
      model: null
    }
  },
  computed: {
    params() {
      const offset = (this.page - 1) * this.limit
      return {
        search: this.query,
        limit: this.limit,
        offset
      }
    },
    totalPages() {
      return Math.ceil(this.count / this.limit)
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  methods: {
    reloadList() {
      this.$emit('reload', { ...this.params })
    },
    search(query) {
      this.query = query
      this.reloadList()
    },
    changePage(page) {
      this.page = page
      this.reloadList()
    },

    addShow() {
      this.model = {}
      this.editDialogVisible = true
    },

    editShow({ id }) {
      agentsService
        .getShowById(id)
        .then(result => {
          this.model = result
          this.editDialogVisible = true
        })
        .catch(() => noticeService.error('Не удалось загрузить данные показа'))
    },
    deleteShow(show) {
      confirmService
        .ask({ title: 'Вы точно хотите удалить показ?' })
        .then(() => {
          this.loading = true
          agentsService
            .deleteShow(show.id)
            .then(() => {
              this.reloadList()
              noticeService.success('Показ удален')
            })
            .catch(() => noticeService.error('Не удалось удалить показ'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
